import React, { useState } from 'react'
import Particles from 'react-particles-js'
import { Bounce } from 'react-reveal'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'
import Typewriter from 'typewriter-effect'
import './Home.css'
// import Navbar from '../navbar/Navbar'
import config from '../../config'
import profile from '../../images/matt.png'
// import { ButtonProducts } from '../button-products'
import Contact from '../contact/Contact'

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  return (
    <div className="home-wrapper">
      <div className="home">
        <Particles className="particles" params={config.particles} />
        <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>
          
          <Fade bottom distance="40px">
            <img
              className="profile"
              alt="m.jigalin profile"
              src={profile}
              onLoad={() => setImageLoaded(true)}
            />
            <h1 className="greeting-text">
              Olá, eu sou <span className="name">Jhonathan Santos</span>{' '}
              <span className="wave-emoji" role="img" aria-label="waving hand">
                !
              </span>

            </h1>
            <h1 className="greeting-text">
              <Typewriter
                options={{
                  strings: [
                    'Um eterno aprendiz',
                    'Apaixonado por negócios',
                    'Empreendedor entusiasta',
                    // 'I create unique digital experiences.',
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                  cursor: '➤',
                  delay: 100,
                }}
              />
              {/* <ButtonProducts></ButtonProducts> */}
              <Contact /> 

            </h1>
            <Bounce cascade>

            </Bounce>
            <div className="scroll-down">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-63}
                duration={500}
              >
                {/* <ArrowDropDownCircleIcon
                  fontSize="large"
                  style={{ pointerEvents: 'fill', cursor: 'pointer' }}
                /> */}
              </Link>
              
            </div>
          </Fade>

        </div>
        {/* <Navbar /> */}

      </div>

    </div>
  )
}

export default Home
