import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <p className="copyright">
      © COPYRIGHT 2023 – Jhonathan Santos – Todos os direitos reservados.
      </p>
    </footer>
  )
}

export default Footer
