import React from 'react'
import { Bounce } from 'react-reveal'
import Section from '../section/Section'
import './Contact.css'
// import ContactForm from '../contactForm/ContactForm'
import discord from '../../images/social/discord.png'
import facebook from '../../images/social/facebook.png'
import github from '../../images/social/github.png'
import instagram from '../../images/social/instagram.png'
import linkedin from '../../images/social/linkedin.png'
// import { HoverEvent } from 'tsparticles/Options/Classes/Interactivity/Events/HoverEvent'

const Contact = () => {
  return (
    <Section title="">
      {/* <ContactForm /> */}
      <Bounce cascade>
        <div className="links">
          <a
            href= "https://github.com/jhonathannnn"
            target="_blank"
            rel="noopener noreferrer"
         
          >
            <img src={github} alt="Github Logo" width="40px" />
          </a>
          <a
            href="https://www.instagram.com/jhonathan_santoss"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram Logo" width="40px" />
          </a>

          <a
            href="https://www.facebook.com/jhonathan_santoss/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook Logo" width="40px" />
          </a>
          <a
            href="https://www.linkedin.com/in/jhonathan-santos/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img src={linkedin} alt="Linkedin Logo" width="50px" />
            </a>
            <a
            href="https://discord.com/channels/@Jhonathan#5183"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img src={discord} alt="Linkedin Logo" width="50px" />
            </a>

        </div>
      </Bounce>
    </Section>
  )
}

export default Contact
